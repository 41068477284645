import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class LoaderWrapper extends PureComponent {
  render() {
    const { isLoading } = this.props;
    return (
      <div className="loader-wrapper" style={{ display: isLoading ? 'flex' : 'none' }}>
        <div className="center">
          <div className="d d1" />
          <div className="d d2" />
          <div className="d d3" />
          <div className="d d4" />
          <div className="d d5" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.loading.isLoading,
});
export default connect(mapStateToProps)(LoaderWrapper);
