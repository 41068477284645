import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import LoaderWrapper from './components/Section/LoaderWrapper';

import configureStore from './store';
import App from './components/App';

const store = configureStore();
const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Suspense fallback={<div />}>
      <LoaderWrapper />
      <App />
    </Suspense>
  </Provider>
);
