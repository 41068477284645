import {
    LOADING_START, LOADING_PENDING_AND_ADVANCE,
    LOADING_INSURANCE_PER_DAY, LOADING_INSURANCE_MONTHLY,
    LOADING_COLLECTION_MONTHLY, LOADING_COLLECTION_PER_DAY,
    LOADING_TOTAL_COLLECTION_BY_AGENT
} from '../constants/actionTypes';

const initialState = {
    isLoading: false,
};

export default function loading(state = initialState, action) {
    switch (action.type) {
        case LOADING_START:
            return {
                ...state,
                isLoading: action.loading,
            };

        case LOADING_PENDING_AND_ADVANCE:
            return {
                ...state,
                isLoadingPendingAndAdvanceAmount: action.loading,
            }

        case LOADING_INSURANCE_PER_DAY:
            return {
                ...state,
                isLoadingInsurancePerDay: action.loading,
            }
        case LOADING_INSURANCE_MONTHLY:
            return {
                ...state,
                isLoadingInsuranceMonthly: action.loading,
            }

        case LOADING_COLLECTION_MONTHLY:
            return {
                ...state,
                isLoadingCollectionMonthly: action.loading,
            }
        case LOADING_COLLECTION_PER_DAY:
            return {
                ...state,
                isLoadingCollectionPerDay: action.loading,
            }

        case LOADING_TOTAL_COLLECTION_BY_AGENT:
            return {
                ...state,
                isLoadingTotalCollectionByAgent: action.loading,
            }

        default:
            return state;
    }
}
