export const APP_LOAD = 'APP_LOAD';
export const NOTIFICATION_UNLOADED = 'NOTIFICATION_UNLOADED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SUBDOMAIN_LOGIN = 'SUBDOMAIN_LOGIN';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const LOADING_START = 'LOADING_START';
export const LOADING_INSURANCE_PER_DAY = 'LOADING_INSURANCE_PER_DAY';
export const LOADING_PENDING_AND_ADVANCE = 'LOADING_PENDING_AND_ADVANCE';
export const LOADING_INSURANCE_MONTHLY = 'LOADING_INSURANCE_MONTHLY';
export const LOADING_COLLECTION_MONTHLY = 'LOADING_COLLECTION_MONTHLY';
export const LOADING_COLLECTION_PER_DAY = 'LOADING_COLLECTION_PER_DAY';
export const LOADING_TOTAL_COLLECTION_BY_AGENT = 'LOADING_TOTAL_COLLECTION_BY_AGENT';

export const STATES_LOADED = 'STATES_LOADED';
export const CITIES_LOADED = 'CITIES_LOADED';
export const CITIES_UNLOADED = 'CITIES_UNLOADED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const COMPANY_USER_PAGE_LOADED = 'COMPANY_USER_PAGE_LOADED';
export const ADD_COMPANY_USER = 'ADD_COMPANY_USER';
export const GET_COMPANY_USER = 'GET_COMPANY_USER';
export const UPDATE_COMPANY_USER = 'UPDATE_USER';
export const COMPANY_USER_PAGE_UNLOADED = 'COMPANY_USER_PAGE_UNLOADED';
export const SEND_COMPANY_USER_MAIL = 'SEND_COMPANY_USER_MAIL';
export const COLLECTION_AGENTS = 'COLLECTION_AGENTS'

export const DAILY_COLLECTION_PAGE_UNLOADED = 'DAILY_COLLECTION_PAGE_UNLOADED';
export const DAILY_COLLECTION_PAGE_LOADED = 'DAILY_COLLECTION_PAGE_LOADED';
export const DAILY_COLLECTION_INSURANCE_PAGE_LOADED = 'DAILY_COLLECTION_INSURANCE_PAGE_LOADED';
export const ADD_DAILY_COLLECTION = 'ADD_DAILY_COLLECTION';
export const GET_DAILY_COLLECTION = 'GET_DAILY_COLLECTION';
export const UPDATE_DAILY_COLLECTION = 'UPDATE_DAILY_COLLECTION';
export const DELETE_DAILY_COLLECTION = 'DELETE_DAILY_COLLECTION';
export const DASHBOARD_STATS = 'DASHBOARD_STATS';

export const PREMIUM_COLLECTION_PAGE_LOADED = 'PREMIUM_COLLECTION_PAGE_LOADED';
export const ADD_PREMIUM_COLLECTION = 'ADD_PREMIUM_COLLECTION';
export const PREMIUM_COLLECTION_PAGE_UNLOADED = 'PREMIUM_COLLECTION_PAGE_UNLOADED';
export const GET_PREMIUM_COLLECTION = 'GET_PREMIUM_COLLECTION';
export const GET_PREMIUM_COLLECTION_SUMMARY = 'GET_PREMIUM_COLLECTION_SUMMARY';
export const DELETE_PREMIUM_COLLECTION = 'DELETE_PREMIUM_COLLECTION';
export const UPDATE_PREMIUM_COLLECTION = 'UPDATE_PREMIUM_COLLECTION';
export const BULK_DELETE_PREMIUM_COLLECTION = 'BULK_DELETE_PREMIUM_COLLECTION';

export const GET_USER_BY_RESET_PASSWORD_TOKEN = 'GET_USER_BY_RESET_PASSWORD_TOKEN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_PAGE_UNLOADED = 'RESET_PASSWORD_PAGE_UNLOADED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const PREVIOUS_POLICY_LOADED = 'PREVIOUS_POLICY_LOADED';
export const DELETE_PREVIOUS_POLICY = 'DELETE_PREVIOUS_POLICY';
export const BULK_DELETE_PREVIOUS_POLICIES = 'BULK_DELETE_PREVIOUS_POLICIES';
export const UPDATE_PREVIOUS_POLICY = 'UPDATE_PREVIOUS_POLICY';
export const PLAN_LOADED = 'PLAN_LOADED';
export const CONTACT_US = 'CONTACT_US';
export const PAYMENT_DETAILS_LOADED = 'PAYMENT_DETAILS_LOADED';

export const USER_NOTIFICATION_PAGE_LOADED = 'USER_NOTIFICATION_PAGE_LOADED';
export const USER_NOTIFICATION_PAGE_UNLOADED = 'USER_NOTIFICATION_PAGE_UNLOADED';
export const USER_NOTIFICATION_SEND = 'USER_NOTIFICATION_SEND';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const BULK_DELETE_CUSTOMER = 'BULK_DELETE_CUSTOMER';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const CUSTOMER_PAGE_LOADED = 'CUSTOMER_PAGE_LOADED';
export const CUSTOMER_PAGE_UNLOADED = 'CUSTOMER_PAGE_UNLOADED';
export const CHANGE_STATUS = 'CHANGE_STATUS';

export const CUSTOMER_INSURANCE_LOADED = 'CUSTOMER_INSURANCE_LOADED';
export const DELETE_CUSTOMER_INSURANCE = 'DELETE_CUSTOMER_INSURANCE';
export const BULK_DELETE_CUSTOMER_INSURANCES = 'BULK_DELETE_CUSTOMER_INSURANCES';
export const UPDATE_CUSTOMER_INSURANCE = 'UPDATE_CUSTOMER_INSURANCE';
export const CUSTOMER_INSURANCES_LOADED = 'CUSTOMER_INSURANCES_LOADED';
export const SEND_CUSTOMER_MAIL = 'SEND_CUSTOMER_MAIL';
export const GET_INSURANCE_DETAIL = 'GET_INSURANCE_DETAIL';

export const CUSTOMER_MEDICAL_HISTORY_LOADED = 'CUSTOMER_MEDICAL_HISTORY_LOADED';
export const DELETE_CUSTOMER_MEDICAL_HISTORY = 'DELETE_CUSTOMER_MEDICAL_HISTORY';
export const BULK_DELETE_CUSTOMER_MEDICAL_HISTORIES = 'BULK_DELETE_CUSTOMER_MEDICAL_HISTORIES';
export const UPDATE_CUSTOMER_MEDICAL_HISTORY = 'UPDATE_CUSTOMER_MEDICAL_HISTORY';
export const CUSTOMER_MEDICAL_HISTORIES_LOADED = 'CUSTOMER_MEDICAL_HISTORIES_LOADED';
export const GET_MEDICAL_HISTORY = 'GET_MEDICAL_HISTORY';

export const CUSTOMER_FAMILY_HISTORY_LOADED = 'CUSTOMER_FAMILY_HISTORY_LOADED';
export const DELETE_CUSTOMER_FAMILY_HISTORY = 'DELETE_CUSTOMER_FAMILY_HISTORY';
export const BULK_DELETE_CUSTOMER_FAMILY_HISTORIES = 'BULK_DELETE_CUSTOMER_FAMILY_HISTORIES';
export const UPDATE_CUSTOMER_FAMILY_HISTORY = 'UPDATE_CUSTOMER_FAMILY_HISTORY';
export const CUSTOMER_FAMILY_HISTORIES_LOADED = 'CUSTOMER_FAMILY_HISTORIES_LOADED';
export const GET_FAMILY_DETAIL = 'GET_FAMILY_DETAIL';

export const CUSTOMER_FINANCIAL_DETAILS_LOADED = 'CUSTOMER_FINANCIAL_DETAILS_LOADED';

export const CUSTOMER_RECEIPT_LOADED = 'CUSTOMER_RECEIPT_LOADED';
export const DELETE_CUSTOMER_RECEIPT = 'DELETE_CUSTOMER_RECEIPT';
export const UPDATE_CUSTOMER_RECEIPT = 'UPDATE_CUSTOMER_FAMILY_HISTORY';
export const CUSTOMER_RECEIPTS_LOADED = 'CUSTOMER_RECEIPTS_LOADED';
export const GET_RECEIPT_DETAIL = 'GET_RECEIPT_DETAIL';

export const UPDATE_COLLECTION_CENTER = 'UPDATE_COLLECTION_CENTER';
export const COLLECTION_CENTER_PAGE_UNLOADED = 'COLLECTION_CENTER_PAGE_UNLOADED';
export const GET_COLLECTION_CENTER = 'GET_COLLECTION_CENTER';
export const ADD_COLLECTION_CENTER = 'ADD_COLLECTION_CENTER';
export const COLLECTION_CENTER_PAGE_LOADED = 'COLLECTION_CENTER_PAGE_LOADED'
export const CHANGE_COLLECTION_CENTER_STATUS = 'CHANGE_COLLECTION_CENTER_STATUS'

export const ADD_COLLECTION_SUBMISSION = 'ADD_COLLECTION_SUBMISSION';
export const COLLECTION_SUBMISSION_PAGE_LOADED = 'COLLECTION_SUBMISSION_PAGE_LOADED';
export const COLLECTION_SUBMISSION_PAGE_UNLOADED = 'COLLECTION_SUBMISSION_PAGE_UNLOADED';
export const GET_COLLECTION_SUBMISSION = 'GET_COLLECTION_SUBMISSION';
export const UPDATE_COLLECTION_SUBMISSION = 'UPDATE_COLLECTION_SUBMISSION';
export const DELETE_COLLECTION_SUBMISSION = 'DELETE_COLLECTION_SUBMISSION'
