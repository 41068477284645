import {
  NOTIFICATION_UNLOADED,
  DAILY_COLLECTION_PAGE_LOADED,
  ADD_DAILY_COLLECTION,
  DAILY_COLLECTION_PAGE_UNLOADED,
  GET_DAILY_COLLECTION,
  UPDATE_DAILY_COLLECTION,
  DELETE_DAILY_COLLECTION,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case ADD_DAILY_COLLECTION:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        insurances: action.payload.insurances,
      };
    case DAILY_COLLECTION_PAGE_LOADED:
      var customers = {};
      var collections = {};
      var collection_centers = {};
      var users = {};
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'customer':
            customers[i.id] = {
              id: i.id, ...i.attributes,
              customer: i.attributes.customer ? { id: i.attributes.customer.id, ...i.attributes.customer.attributes } : {}
            }
            break;
          case 'daily_collection':
            collections[i.id] = { id: i.id, ...i.attributes }
            break;
          case 'collection_center':
            collection_centers[i.id] = { id: i.id, ...i.attributes }
            break;
          case 'user':
            users[i.id] = { id: i.id, ...i.attributes }
            break;
          default:
            break;
        }
      })
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        expectedCollection: action.payload.meta ? action.payload.meta.expected_collection : 0,
        actualCollection: action.payload.meta ? action.payload.meta.actual_collection : 0,
        total_collections: action.payload.meta.page.total || 0,
        premium_collections: action.payload.data ? action.payload.data.map((i) => (
          {
            id: i.id, ...i.attributes,
            customer: customers[i.relationships.customer.data.id],
            collection: collections[i.relationships.collection.data?.id],
            collection_center: collection_centers[i.relationships.collection_center.data?.id],
            collection_agent: users[i.relationships.collection_agent.data?.id]
          }
        )) : [],
      };
    case GET_DAILY_COLLECTION:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        daily_collection: action.payload.data && !Array.isArray(action.payload.data) ? action.payload.data.attributes : {},
      };
    case UPDATE_DAILY_COLLECTION:
    case DELETE_DAILY_COLLECTION:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state, inProgress: false, errors: '',
      };
    case DAILY_COLLECTION_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
