import {
  NOTIFICATION_UNLOADED,
  ADD_COMPANY_USER,
  COMPANY_USER_PAGE_LOADED,
  SEND_COMPANY_USER_MAIL,
  GET_COMPANY_USER,
  COMPANY_USER_PAGE_UNLOADED,
  UPDATE_COMPANY_USER,
  COLLECTION_AGENTS,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case COLLECTION_AGENTS:
      const companyUsers = action.payload.data.map((i) => ({ id: i.id, ...i.attributes }));

      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        companyUsers
      };
    case GET_COMPANY_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        companyUser: action.payload.data ? {id: action.payload.data.id, ...action.payload.data.attributes } : {},
      };
    case ADD_COMPANY_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
      };
    case COMPANY_USER_PAGE_LOADED:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        companyUsers: action.payload.data ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
        add_more_users: action.payload.data.length > 0 ? action.payload.data[0].attributes.add_more_company_user : true,
        total_users: action.payload.meta.page.total || 0,
      };
    case UPDATE_COMPANY_USER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
      };
    case SEND_COMPANY_USER_MAIL:
      if (action.payload.errors) {
        return {
          ...state,
          inProgress: false,
          errors: action.payload.errors,
          message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        message: action.payload.meta?.message,
      };
    case COMPANY_USER_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
