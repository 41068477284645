import {
  NOTIFICATION_UNLOADED,
  APP_LOAD,
  LOGOUT,
  LOGIN,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  UPDATE_PROFILE,
  SUBDOMAIN_LOGIN,
  PLAN_LOADED, CONTACT_US, PAYMENT_DETAILS_LOADED,
} from '../constants/actionTypes';


const defaultState = {
  appName: 'EnsureXpert',
  projectName: 'EnsureXpert',
  token: null,
  currentUser: null,
  error: false,
  errors: null,
};

export default (state = defaultState, action) => {
  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null,
      };
    case UPDATE_PROFILE:
      if (error) return { ...state };
      return { ...state, currentUser: { id: action.payload.data.id, ...action.payload.data.attributes } };
    case LOGOUT:
      return {
        ...state, token: null, currentUser: null,
      };
    case SUBDOMAIN_LOGIN:
      return {
        ...state,
        inProgress: false,
        errors: errors,
      };
    case LOGIN:
      if (error) {
        return {
          ...state,
          inProgress: false,
          errors,
          message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: null,
        currentUser: { id: action.payload.data.id, ...action.payload.data.attributes }
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case REGISTER:
    case PAYMENT_DETAILS_LOADED:
      return { ...state };
    case PLAN_LOADED:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        plans: action.payload.data && Array.isArray(action.payload.data) ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
      };
    case CONTACT_US:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.message,
      };
    default:
      return state;
  }
};
