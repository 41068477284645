import {
  NOTIFICATION_UNLOADED,
  PREMIUM_COLLECTION_PAGE_LOADED,
  ADD_PREMIUM_COLLECTION,
  PREMIUM_COLLECTION_PAGE_UNLOADED,
  GET_PREMIUM_COLLECTION,
  GET_PREMIUM_COLLECTION_SUMMARY,
  DELETE_PREMIUM_COLLECTION,
  UPDATE_PREMIUM_COLLECTION,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);

  const customers = {};
  const collection_centers = {};
  const users = {};

  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case ADD_PREMIUM_COLLECTION:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        insurances: action.payload.data && Array.isArray(action.payload.data) ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
        premium_collection: action.payload.data && !Array.isArray(action.payload.data) ? action.payload.data.attributes : {},
      };
    case PREMIUM_COLLECTION_PAGE_LOADED:
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'customer':
            customers[i.id] = {
              id: i.id, ...i.attributes,
              customer: i.attributes.customer ? { id: i.attributes.customer.id, ...i.attributes.customer.attributes } : {}
            }
            return
          case 'collection_center':
            collection_centers[i.id] = { id: i.id, ...i.attributes }
            return
          case 'user':
            users[i.id] = { id: i.id, ...i.attributes }
            return
          default:
            return;
        }
      })

      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        total_premium_collections: action.payload.meta.page.total || 0,
        premium_collections: action.payload.data ? action.payload.data.map((i) => (
          {
            id: i.id, ...i.attributes,
            customer: customers[i.relationships.customer.data.id],
            collectionCenter: collection_centers[i.relationships.collection_center.data?.id],
            collectionAgent: users[i.relationships.collection_agent.data?.id],
          }
        )) : [],
      };
    case UPDATE_PREMIUM_COLLECTION:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state, inProgress: false, errors: '',
      };
    case DELETE_PREMIUM_COLLECTION:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        premium_collections: state.premium_collections.filter((premium_collection) => parseInt(premium_collection.id, 0) !== parseInt(action.premium_collection_id, 0)),
      };
    case GET_PREMIUM_COLLECTION:
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'customer':
            customers[i.id] = {
              id: i.id, ...i.attributes,
              customer: i.attributes.customer ? { id: i.attributes.customer.id, ...i.attributes.customer.attributes } : {}
            }
            return
          case 'collection_center':
            collection_centers[i.id] = { id: i.id, ...i.attributes }
            return
          case 'user':
            users[i.id] = { id: i.id, ...i.attributes }
            return
          default:
            return;
        }
      })
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        premium_collection: action.payload.data && !Array.isArray(action.payload.data) ? {
          id: action.payload.data.id, ...action.payload.data.attributes,
          customer: customers[action.payload.data.relationships.customer.data.id],
          collectionCenter: collection_centers[action.payload.data.relationships.collection_center.data?.id],
          collectionAgent: users[action.payload.data.relationships.collection_agent.data?.id],
        } : {},
      };
    case GET_PREMIUM_COLLECTION_SUMMARY:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        collections: action.payload.data ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
      };
    case PREMIUM_COLLECTION_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
