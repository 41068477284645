import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  REGISTER,
  LOADING_START,
} from './constants/actionTypes';

function isPromise(v) {
  return v && typeof v.then === 'function';
}

const promiseMiddleware = (store) => (next) => (action) => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });
    store.dispatch({ type: LOADING_START, loading: true });

    action.payload.then(
      (res) => {
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
        store.dispatch({ type: LOADING_START, loading: false });
      },
      (error) => {
        action.payload = error.response.body;
        store.dispatch(action);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        store.dispatch({ type: LOADING_START, loading: false });
      },
    );

    return;
  }
  next(action);
};

const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGIN) {
    if (action.payload && action.payload.data) {
      window.localStorage.setItem('jwt', action.payload.data.attributes.access_token);
    }
  } else if (action.type === REGISTER || action.type === LOGOUT) {
    window.localStorage.removeItem('jwt');
  } else if (action.payload?.error === 'invalid_token') {
    window.localStorage.removeItem('jwt');
    window.location.href = window.location.origin + '/login'

  }
  next(action);
};

export { promiseMiddleware, localStorageMiddleware };
