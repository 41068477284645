import {
  NOTIFICATION_UNLOADED,

  STATES_LOADED,
  CITIES_LOADED,
  CITIES_UNLOADED,

} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case STATES_LOADED:
      const states = action.payload.data.map((i) => ({ id: i.id, ...i.attributes }));
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        states,
      };
    case CITIES_LOADED:
      const cities = action.payload.data.map((i) => ({ id: i.id, ...i.attributes }));
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        cities,
      };
    case CITIES_UNLOADED:
      return {
        ...state,
        inProgress: false,
        cities: [],
      };
    default:
      return state;
  }
};
