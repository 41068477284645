
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { appToken } from './util/helpers/app';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = `${process.env.REACT_APP_API_HOST}`;
const V1 = '/api/v1';
const V2 = '/api/v2';

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
  const token = appToken();
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
};

const jsonApiVender = (req) => {
  req.set('Content-Type', 'application/vnd.api+json');
  req.set('Accept', 'application/vnd.api+json');
};

const requests = {
  del: (url, body, version = V1) => superagent.del(`${API_ROOT}${version}${url}`).send(body)
    .use(tokenPlugin)
    .use(version === V2 ? jsonApiVender : () => { })
    .then(responseBody),
  get: (url, params, version = V1) => superagent.get(`${API_ROOT}${version}${url}`, params)
    .use(tokenPlugin)
    .then(responseBody),
  put: (url, body, version = V1) => superagent.put(`${API_ROOT}${version}${url}`, body)
    .use(tokenPlugin)
    .use(version === V2 ? jsonApiVender : () => { })
    .then(responseBody),
  post: (url, body, version = V1) => {
    var filter
    if (body) {
      filter = body.filter
      delete (body.filter)
    }

    return superagent.post(`${API_ROOT}${version}${url}`, body)
      .use((req) => { req.query(filter ? { filter } : null); return (req) })
      .use(tokenPlugin)
      .use(version === V2 ? jsonApiVender : () => { })
      .then(responseBody)
  },
};

const Auth = {
  current: () => requests.get('/users'),
  checkDomain: (subdomain) => requests.get(`/unauth/companies/${subdomain}`, {}, V2),
  login: (emailOrPhone, password, subdomain) => requests.post('/oauth/token', {
    data: {
      type: 'token', attributes: {
        "grant_type": "password", "email_or_phone": emailOrPhone, "password": password, "subdomain": subdomain
      }
    }
  }, V2),
  register: (name, phone, email, companyName, subdomain, password, passwordConfirmation, planDetails) => requests.post('/users', {
    name, phone, email, company_name: companyName, subdomain, password, password_confirmation: passwordConfirmation, plan_details: planDetails,
  }),
  save: (user) => requests.put('/users/registrations', { data: { type: 'users/register', attributes: user } }, V2),
};

const Customer = {
  all: (filter, page = {}) => requests.get('/customers', { filter, page }, V2),
  create: (customer) => requests.post('/customers', { data: { type: 'customer', attributes: customer } }, V2),
  get: (id) => requests.get(`/customers/${id}`, {}, V2),
  set: (id, customer) => requests.put(`/customers/${id}`, { data: { type: 'customer', attributes: customer } }, V2),
  del: (id) => requests.del(`/customers/${id}`, null, V2),
  bulkDel: (ids) => requests.del('/customers', { data: { type: 'customer', attributes: { ids: ids.join(',') } } }, V2),
  confirmations: {
    create: (id, filter) => requests.post(`/customers/${id}/confirmations`, { filter }, V2),
  },
};

const CustomerInsurance = {
  all: (cid, filter, page = {}) => requests.get(`/customers/${cid}/insurances`, { filter, page }, V2),
  create: (cid, insurance) => requests.post(`/customers/${cid}/insurances`, { data: { type: 'customers/insurance', attributes: insurance } }, V2),
  get: (cid, id) => requests.get(`/customers/${cid}/insurances/${id}`, {}, V2),
  set: (cid, id, insurance) => requests.put(`/customers/${cid}/insurances/${id}`, { data: { type: 'customers/insurance', attributes: insurance } }, V2),
  del: (cid, id) => requests.del(`/customers/${cid}/insurances/${id}`, null, V2),
  bulkDel: (cid, ids) => requests.del(`/customers/${cid}/insurances`, { data: { type: 'customers/insurance_ids', attributes: { ids: ids.join(',') } } }, V2),
};

const CustomerMedicalHistory = {
  all: (cid, filter, page) => requests.get(`/customers/${cid}/medical_histories`, { filter, page }, V2),
  create: (cid, history) => requests.post(`/customers/${cid}/medical_histories`, { data: { type: 'customers/medical_history', attributes: history } }, V2),
  get: (cid, id) => requests.get(`/customers/${cid}/medical_histories/${id}`, {}, V2),
  set: (cid, id, history) => requests.put(`/customers/${cid}/medical_histories/${id}`, { data: { type: 'customers/medical_history', attributes: history } }, V2),
  del: (cid, id) => requests.del(`/customers/${cid}/medical_histories/${id}`, null, V2),
  bulkDel: (cid, ids) => requests.del(`/customers/${cid}/medical_histories`, { data: { type: 'customers/medical_history_ids', attributes: { ids: ids.join(',') } } }, V2),
};

const CustomerFamilyHistory = {
  all: (cid, filter, page) => requests.get(`/customers/${cid}/family_histories`, { filter, page }, V2),
  create: (cid, history) => requests.post(`/customers/${cid}/family_histories`, { data: { type: 'customers/family_history', attributes: history } }, V2),
  get: (cid, id) => requests.get(`/customers/${cid}/family_histories/${id}`, {}, V2),
  set: (cid, id, history) => requests.put(`/customers/${cid}/family_histories/${id}`, { data: { type: 'customers/family_history', attributes: history } }, V2),
  del: (cid, id) => requests.del(`/customers/${cid}/family_histories/${id}`, null, V2),
  bulkDel: (cid, ids) => requests.del(`/customers/${cid}/family_histories`, { data: { type: 'customers/family_history_ids', attributes: { ids: ids.join(',') } } }, V2),
};

const CustomerFinancialDetail = {
  all: (cid, _page) => requests.get(`/customers/${cid}/financial_details`, {}, V2),
};

const CustomerReceipt = {
  all: (cid, page = {}) => requests.get(`/customers/${cid}/receipts`, { page }, V2),
  create: (cid, history) => requests.post(`/customers/${cid}/receipts`, { data: { type: 'customers/receipt', attributes: history } }, V2),
  get: (cid, id) => requests.get(`/customers/${cid}/receipts/${id}`, {}, V2),
  set: (cid, id, history) => requests.put(`/customers/${cid}/receipts/${id}`, { data: { type: 'customers/receipt', attributes: history } }, V2),
  del: (cid, id) => requests.del(`/customers/${cid}/receipts/${id}`, null, V2),
};

const State = {
  all: (_page) => requests.get('/states', {}, V2),
  getCities: (stateId) => requests.get(`/states/${stateId}/cities`, {}, V2),
};

const User = {
  validResetPasswordToken: (user) => requests.get('/users/password/new', { user }, V2),
  forgot: (user) => requests.post('/users/password', { data: { type: 'users/password', attributes: user } }, V2),
  setPassword: (user) => requests.put('/users/password', { data: { type: 'users/password', attributes: user } }, V2),
  resetPassword: (user) => requests.put('/users/password', { data: { type: 'users/password', attributes: user } }, V2),
};

const CompanyUser = {
  create: (user) => requests.post('/users', { data: { type: 'user', attributes: user } }, V2),
  get: (id) => requests.get(`/users/${id}`, {}, V2),
  all: (filter, page = {}) => requests.get('/users', { filter, page }, V2),
  update: (id, user) => requests.put(`/users/${id}`, { data: { type: 'user', attributes: user } }, V2),
  confirmations: {
    create: (id) => requests.post(`/users/${id}/confirmations`, null, V2),
  },
};

const UserV2 = {
  all: (filter) => requests.get('/users', { filter }, V2)
}

const CollectionCenter = {
  all: (filter, page = {}) => requests.get('/collection_centers', { filter, page }, V2),
  get: (id) => requests.get(`/collection_centers/${id}`, {}, V2),
  create: (collection_center) => requests.post('/collection_centers', { data: { type: 'collection_center', attributes: collection_center } }, V2),
  update: (id, collection_center) => requests.put(`/collection_centers/${id}`, { data: { type: 'collection_center', attributes: collection_center } }, V2),
};

const CollectionSubmission = {
  all: (filter, page = {}) => requests.get('/collection_submissions', { filter, page }, V2),
  create: (collectionSubmission) => requests.post('/collection_submissions', { data: { type: 'collection_submission', attributes: collectionSubmission } }, V2),
  get: (id) => requests.get(`/collection_submissions/${id}`, {}, V2),
  del: (id) => requests.del(`/collection_submissions/${id}`, null, V2),
  bulkDel: (ids) => requests.del('/collection_submissions', { data: { type: 'collection_submission', attributes: { ids: ids.join(',') } } }, V2),
  update: (id, history) => requests.put(`/collection_submissions/${id}`, { data: { type: 'collection_submission', attributes: history } }, V2),
}

const DailyCollection = {
  all: (filter) => requests.get('/daily_collections', { filter }, V2),
  create: (dailyCollection) => requests.post('/daily_collections', { data: { type: 'daily_collection', attributes: dailyCollection } }, V2),
  get: (id) => requests.get(`/daily_collections/${id}`, {}, V2),
  del: (id) => requests.del(`/daily_collections/${id}`, null, V2),
  bulkDel: (ids) => requests.del('/daily_collections', { data: { type: 'daily_collection', attributes: { ids: ids.join(',') } } }, V2),
  update: (id, history) => requests.put(`/daily_collections/${id}`, { data: { type: 'daily_collection', attributes: history } }, V2),
};

const PremiumCollection = {
  all: (filter, page = {}) => requests.get('/premium_collections', { filter, page }, V2),
  create: (premiumCollection) => requests.post('/premium_collections', { data: { type: 'premium_collection', attributes: premiumCollection } }, V2),
  get: (id) => requests.get(`/premium_collections/${id}`, {}, V2),
  withFilter: (mode, value) => requests.get('/premium_collections', { filter: { mode, value } }, V2),
  del: (id) => requests.del(`/premium_collections/${id}`, null, V2),
  bulkDel: (ids) => requests.del('/premium_collections', { data: { type: 'premium_collection', attributes: { ids: ids.join(',') } } }, V2),
  update: (id, history) => requests.put(`/premium_collections/${id}`, { data: { type: 'premium_collection', attributes: history } }, V2),
};

const Stats = {
  overall: (params) => requests.get(`/general/graph/pie?fields[general/graphs]=${params.field}`, { key: params.key }, V2),
  graphCount: (params) => requests.get(`/general/count?fields[general/counts]=${params.field}`, {}, V2),
  lineChartData: (params) => requests.get(`/general/graph/line?filter=${params.filter}&fields[general/graphs]=${params.field}`, { key: params.key }, V2),
};

const Plan = {
  all: (params) => requests.get('/unauth/plans', params, V2),
  contactUsSubmit: (id, planEnquiry) => requests.post(`/unauth/plans/${id}/plan_enquiries`, { data: { type: 'plan_enquiry', attributes: planEnquiry } }, V2),
};

const Payment = {
  getPaymentDetails: (token) => requests.get('/payments/details', { token }),
  contactUsInvoiceSubmit: (invoiceRequest) => requests.post('/payments/invoice_request', { invoice_request: invoiceRequest }),
};

const UserNotification = {
  all: (filter) => requests.get('/notifications', { filter }, V2),
  send: (id) => requests.post(`/notifications/${id}/send`, { data: { type: 'notification', attributes: {} } }, V2),
  read: (id) => requests.post('/notifications/read', { data: { type: 'notification', attributes: { ids: id } } }, V2),
  bulkRead: (ids) => requests.post('/notifications/read', { data: { type: 'notification', attributes: { ids: ids.join(',') } } }, V2),
};

export default {
  Auth, // V2*
  User, // V2
  CompanyUser, // V2
  Payment,
  DailyCollection, // V2
  PremiumCollection, // V2
  Stats, // V2
  Plan, // V2
  UserNotification, // V2
  UserV2, // V2
  State, // V2
  Customer, // V2
  CustomerInsurance, // V2
  CustomerMedicalHistory, // V2
  CustomerFamilyHistory, // V2
  CustomerReceipt, // V2
  CustomerFinancialDetail, // V2
  CollectionCenter, // V2
  CollectionSubmission, //V2
};
