import React from "react";

export const rowClassNameFormat = (row, rowIdx) => (rowIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example');
export const paginationTotal = (start, to, total) => (
  <span className="react-bootstrap-table-pagination-total">
    From
    {' '}
    <span style={{ fontWeight: 'bold' }}>{start}</span>
    {' '}
    to
    {' '}
    <span style={{ fontWeight: 'bold' }}>{to}</span>
    ,
    out of
    {' '}
    <span style={{ fontWeight: 'bold' }}>{total}</span>
    {' '}
    records
  </span>
)
// NOTE : considering only 0 or 1 suffixes
export const windowLocationDomain = () => {
  const { location } = window;
  let domains = location.host.split('.');
  if (domains.length === 1) domains = [null, domains[0], null];
  if (domains.length === 2) {
    // considering (:) Means Localhost
    domains = location.host.includes('localhost') ? [...domains, null] : [null, ...domains];
  }
  domains.reverse();

  return {
    subDomains: domains.slice(2).reverse(),
    subdomain: domains.slice(2).reverse()[0],
    // topLevelDomains: domains[0]
  };
}

export const roleFormatter = (cell) => {
  const roles = []
  if(cell.includes('company_admin')) roles.push('Admin')
  if(cell.includes('company_user')) roles.push('Agent')
  if(cell.includes('company_user_sub_agent')) roles.push('Sub Agent')
  if(cell.includes('company_user_collection_agent')) roles.push('Collection Agent')
  return(roles.join(' + '))
}
