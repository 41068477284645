import {
  NOTIFICATION_UNLOADED,
  PAYMENT_DETAILS_LOADED,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case PAYMENT_DETAILS_LOADED:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        payment_detail: action.payload ? action.payload.payment_detail : null,
      };
    default:
      return state;
  }
};
