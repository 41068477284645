import { combineReducers } from 'redux';
import common from './common';
import auth from './auth';
import state from './state';
import customer from './customer';
import profile from './profile';
import companyUser from './companyUser';
import dailyCollection from './dailyCollection';
import premiumCollection from './premiumCollection';
import dashboard from './dashboard';
import payment from './payment';
import notification from './notification';
import collectionCenter from './collectionCenter'
import collectionSubmission from './collectionSubmission';
import loading from './loading';

export default combineReducers({
  auth,
  state,
  customer,
  profile,
  dailyCollection,
  common,
  companyUser,
  dashboard,
  premiumCollection,
  payment,
  notification,
  collectionCenter,
  collectionSubmission,
  loading,
});
