import React, { Component as RComponent } from 'react';

import { NOTIFICATION_UNLOADED } from '../constants/actionTypes';

class AppComponent extends RComponent {
  componentWillUnmount() {
    this.props.onNotificationUnload();
  }

  baseHost = (onlyName = false) => {
    const { location } = window;

    return (onlyName ? location.hostname : location.host).replace(/^(api|www|staging|stage)\./gi, '');
  }
}

const appDispatchToProps = (dispatch) => ({
  onNotificationUnload: () => dispatch({ type: NOTIFICATION_UNLOADED }),
});

export { AppComponent, appDispatchToProps };

export default React;
