import {
  NOTIFICATION_UNLOADED,
  CHANGE_PASSWORD,
  UPDATE_PROFILE,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        inProgress: false,
        message: action.payload.meta?.message || action.payload.message,
        errors: action.payload.errors,
        currentUser: action.payload.user,
      };
    case UPDATE_PROFILE:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        message: action.payload.message || action.payload.meta?.message,
        errors: '',
        currentUser: { id: action.payload.data.id, ...action.payload.data.attributes }
      };
    default:
      return state;
  }
};
