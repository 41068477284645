import {
  NOTIFICATION_UNLOADED,
  READ_NOTIFICATION,
  USER_NOTIFICATION_PAGE_LOADED,
  USER_NOTIFICATION_PAGE_UNLOADED, USER_NOTIFICATION_SEND,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case USER_NOTIFICATION_PAGE_LOADED:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        notifications: action.payload.data ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
      };
    case USER_NOTIFICATION_SEND:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.message,
        notifications: action.payload.data ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
      };
    case READ_NOTIFICATION:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.message,
        notifications: action.payload.data ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
      };
    case USER_NOTIFICATION_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
