import {
  COLLECTION_SUBMISSION_PAGE_LOADED,
  ADD_COLLECTION_SUBMISSION,
  COLLECTION_SUBMISSION_PAGE_UNLOADED,
  GET_COLLECTION_SUBMISSION,
  UPDATE_COLLECTION_SUBMISSION,
  DELETE_COLLECTION_SUBMISSION,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);

  const collectionCenters = {};
  const users = {};
  switch (action.type) {
    case ADD_COLLECTION_SUBMISSION:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
      };
    case COLLECTION_SUBMISSION_PAGE_LOADED:
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'collection_center':
            collectionCenters[i.id] = { id: i.id, ...i.attributes }
            break;
          case 'user':
            users[i.id] = { id: i.id, ...i.attributes }
            break;
          default:
            break;
        }
      })
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        onHandTotal: action.payload.meta.current_on_hand,
        total_collectionSubmissions: action.payload.meta.page.total || 0,
        collectionSubmissions: action.payload.data ? action.payload.data.map((i) => (
          {
            id: i.id, ...i.attributes,
            collection_center: collectionCenters[i.relationships.collection_center.data?.id],
            collection_agent: users[i.relationships.collection_agent.data?.id]
          }
        )) : [],
      };
    case GET_COLLECTION_SUBMISSION:
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'collection_center':
            collectionCenters[i.id] = { id: i.id, ...i.attributes }
            break;
          case 'user':
            users[i.id] = { id: i.id, ...i.attributes }
            break;
          default:
            break;
        }
      })
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        collectionSubmission: action.payload.data ? {
          id: action.payload.data.id, ...action.payload.data.attributes,
          collection_center: collectionCenters[action.payload.data.relationships.collection_center.data?.id],
          collection_agent: users[action.payload.data.relationships.collection_agent.data?.id]
        } : null,
      };
    case UPDATE_COLLECTION_SUBMISSION:
    case DELETE_COLLECTION_SUBMISSION:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state, inProgress: false, errors: '',
      };
    case COLLECTION_SUBMISSION_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
