import {
  NOTIFICATION_UNLOADED,

  CUSTOMER_PAGE_LOADED,
  DELETE_CUSTOMER,
  GET_CUSTOMER,
  ADD_CUSTOMER,
  UPDATE_CUSTOMER,
  SEND_CUSTOMER_MAIL,
  CHANGE_STATUS,

  CUSTOMER_INSURANCE_LOADED,
  DELETE_CUSTOMER_INSURANCE,
  UPDATE_CUSTOMER_INSURANCE,
  CUSTOMER_INSURANCES_LOADED,
  GET_INSURANCE_DETAIL,

  CUSTOMER_MEDICAL_HISTORY_LOADED,
  DELETE_CUSTOMER_MEDICAL_HISTORY,
  UPDATE_CUSTOMER_MEDICAL_HISTORY,
  CUSTOMER_MEDICAL_HISTORIES_LOADED,
  GET_MEDICAL_HISTORY,

  CUSTOMER_FAMILY_HISTORY_LOADED,
  DELETE_CUSTOMER_FAMILY_HISTORY,
  UPDATE_CUSTOMER_FAMILY_HISTORY,
  CUSTOMER_FAMILY_HISTORIES_LOADED,
  GET_FAMILY_DETAIL,

  CUSTOMER_FINANCIAL_DETAILS_LOADED,

  CUSTOMER_RECEIPT_LOADED,
  DELETE_CUSTOMER_RECEIPT,
  UPDATE_CUSTOMER_RECEIPT,
  CUSTOMER_RECEIPTS_LOADED,
  GET_RECEIPT_DETAIL,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  var nominees = {};

  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case GET_CUSTOMER:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      const { data } = action.payload;
      return {
        ...state,
        inProgress: false,
        errors: '',
        customer: { id: data.id, ...data.attributes },
        message: '',
      };
    case ADD_CUSTOMER:
      const customerData = action.payload.data;
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        customer: customerData ? { id: customerData.id, ...customerData.attributes } : null,
        customers: action.payload ? action.payload.customers : [],
        add_more_customers: action.payload ? action.payload.add_more_customers : [],
      };
    case SEND_CUSTOMER_MAIL:
      if (error) {
        return {
          ...state,
          inProgress: false,
          errors,
          message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        message: action.payload.meta?.message,
      };
    case CHANGE_STATUS:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        customer: action.payload.data ? { id: action.payload.data.id, ...action.payload.data.attributes } : null,
      };
    case UPDATE_CUSTOMER:
      const updateCustomerData = action.payload.data;
      if (error) {
        return {
          ...state,
          customer: updateCustomerData ? { id: updateCustomerData.id, ...updateCustomerData.attributes } : null,
          inProgress: false,
          errors,
          message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        message: action.payload.meta?.message,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        customers: state.customers.filter((customer) => parseInt(customer.id, 0) !== parseInt(action.customerId, 0)),
      };
    case CUSTOMER_INSURANCE_LOADED:
      const insurance = action.payload.data;
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        insurance: insurance ? { id: insurance.id, ...insurance.attributes } : {},
      };
    case DELETE_CUSTOMER_INSURANCE:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        insurances: state.insurances.filter((insurance) => parseInt(insurance.id, 0) !== parseInt(action.insuranceId, 0)),
      };
    case UPDATE_CUSTOMER_INSURANCE:
      if (error) {
        return {
          ...state, inProgress: false, errors, message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        message: action.payload.meta?.message,
      };
    case CUSTOMER_INSURANCES_LOADED:
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'customers/nominee':
            nominees[i.id] = { id: i.id, ...i.attributes }
            break;
          default:
            break;
        }
      })

      const insurances = action.payload.data ? action.payload.data.map((i) => ({
        id: i.id, ...i.attributes,
        customer: i.attributes.customer ? { id: i.attributes.customer.id, ...i.attributes.customer.attributes  } : {},
        nominees: (i.relationships.nominees?.data || []).map(n => nominees[n.id]),
      })) : [];
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        insurances: insurances,
        total_insurances: action.payload.meta.page.total || 0,
      };
    case CUSTOMER_MEDICAL_HISTORY_LOADED:
      const medical_history = action.payload.data;
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        medical_history,
      };
    case GET_MEDICAL_HISTORY:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        medical_history: action.payload.data && !Array.isArray(action.payload.data) ? { id: action.payload.data.id, ...action.payload.data.attributes} : {},
      };
    case GET_FAMILY_DETAIL:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        family_detail: action.payload.data && !Array.isArray(action.payload.data) ? { id: action.payload.data.id, ...action.payload.data.attributes} : {},
      };
    case GET_RECEIPT_DETAIL:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        receipt_detail: action.payload.data && !Array.isArray(action.payload.data) ? { id: action.payload.data.id, ...action.payload.data.attributes} : {},
      };
    case GET_INSURANCE_DETAIL:
      (action.payload.included || []).forEach((i) => {
        switch (i.type) {
          case 'customers/nominee':
            nominees[i.id] = { id: i.id, ...i.attributes }
            break;
          default:
            break;
        }
      })

      const cust_insurance = action.payload.data ? ({
        id: action.payload.data.id, ...action.payload.data.attributes,
        customer: action.payload.data.attributes.customer ? { id: action.payload.data.attributes.customer.id, ...action.payload.data.attributes.customer.attributes  } : {},
        nominees: (action.payload.data.relationships.nominees?.data || []).map(n => nominees[n.id]),
        medical_history: action.payload.data.attributes.medical_history ? { id: action.payload.data.attributes.medical_history.id, ...action.payload.data.attributes.medical_history.attributes  } : {},
      }) : [];

      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        insurance: cust_insurance
      };
    case DELETE_CUSTOMER_MEDICAL_HISTORY:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        medical_histories: state.medical_histories.filter((i) => parseInt(i.id, 0) !== parseInt(action.insuranceId, 0)),
      };
    case UPDATE_CUSTOMER_MEDICAL_HISTORY:
      if (error) {
        return {
          ...state,
          inProgress: false,
          errors,
          message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        message: action.payload.meta?.message,
      };
    case CUSTOMER_MEDICAL_HISTORIES_LOADED:
      const medical_histories = action.payload ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [];
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        medical_histories,
        total_medical_details: action.payload.meta.page.total || 0,
      };
    case CUSTOMER_FAMILY_HISTORY_LOADED:
      const family_history = action.payload.data;
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        family_history,
      };
    case DELETE_CUSTOMER_FAMILY_HISTORY:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        family_histories: state.family_histories.filter((i) => parseInt(i.id, 0) !== parseInt(action.insuranceId, 0)),
      };
    case UPDATE_CUSTOMER_FAMILY_HISTORY:
      if (error) {
        return {
          ...state, inProgress: false, errors,
          message: '',
        };
      }
      return {
        ...state,
        inProgress: false,
        errors: '',
        message: action.payload.meta?.message,
      };
    case CUSTOMER_FAMILY_HISTORIES_LOADED:
      const family_histories = action.payload ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [];
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        family_histories,
        total_family_details: action.payload.meta.page.total || 0
      };
    case CUSTOMER_FINANCIAL_DETAILS_LOADED:
      const financialDetail = action.payload ? action.payload.data.attributes : {};
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        financialDetail,
      };
      case CUSTOMER_RECEIPT_LOADED:
        const receipt = action.payload.data;
        return {
          ...state,
          inProgress: false,
          errors: action.payload.errors,
          message: action.payload.meta?.message,
          receipt,
        };
      case DELETE_CUSTOMER_RECEIPT:
        return {
          ...state,
          inProgress: false,
          errors: action.payload.errors,
          receipts: state.receipts.filter((i) => parseInt(i.id, 0) !== parseInt(action.insuranceId, 0)),
        };
      case UPDATE_CUSTOMER_RECEIPT:
        if (error) {
          return {
            ...state, inProgress: false, errors,
            message: '',
          };
        }
        return {
          ...state,
          inProgress: false,
          errors: '',
          message: action.payload.meta?.message,
        };
    case CUSTOMER_RECEIPTS_LOADED:
      const receipts = action.payload ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [];
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        receipts,
        total_receipt_details: action.payload.meta.page.total || 0,
      };
    case CUSTOMER_PAGE_LOADED:
      const customers = action.payload.data.map((i) => ({ id: i.id, ...i.attributes }));
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        customers,
        add_more_customers: action.payload ? action.payload.add_more_customers : [],
        total_customers: action.payload.meta.page.total || 0,
      };
    default:
      return state;
  }
};
