import {
  NOTIFICATION_UNLOADED,
  DASHBOARD_STATS,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case DASHBOARD_STATS:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        counts: action.payload ? action.payload.counts : null,
      };
    default:
      return state;
  }
};
