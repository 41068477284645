import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { promiseMiddleware, localStorageMiddleware } from './middleware';
import reducer from './reducers';

export default function configureStore(preloadedState) {
  const middlewareEnhancer = applyMiddleware(...[
    ReduxThunk, promiseMiddleware, localStorageMiddleware,
  ]);
  const composedEnhancers = compose(...[middlewareEnhancer]);
  const store = createStore(reducer, preloadedState, composedEnhancers);
  return store;
}
