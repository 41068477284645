import {
  COLLECTION_CENTER_PAGE_LOADED,
  ADD_COLLECTION_CENTER,
  GET_COLLECTION_CENTER,
  COLLECTION_CENTER_PAGE_UNLOADED,
  UPDATE_COLLECTION_CENTER,
  CHANGE_COLLECTION_CENTER_STATUS,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_COLLECTION_CENTER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        collectionCenter: action.payload.data && !Array.isArray(action.payload.data) ? { id: action.payload.data.id, ...action.payload.data.attributes } : null,
      };
    case ADD_COLLECTION_CENTER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
      };
    case CHANGE_COLLECTION_CENTER_STATUS:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        message: action.payload.meta?.message,
        collectionCenter: action.payload.data ? { id: action.payload.data.id, ...action.payload.data.attributes } : null,
      };
    case COLLECTION_CENTER_PAGE_LOADED:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
        collectionCenters: action.payload.data ? action.payload.data.map((i) => ({ id: i.id, ...i.attributes })) : [],
        total_collection_centers: action.payload.meta.page.total || 0,
      };
    case UPDATE_COLLECTION_CENTER:
      return {
        ...state,
        inProgress: false,
        errors: action.payload.errors,
      };
    case COLLECTION_CENTER_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
