import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { windowLocationDomain } from '../components/Utils';
import { appToken } from '../util/helpers/app';
import agent from '../agent';
import { currentUserLoad } from '../actions/auth';

import './Utils/css/main.css';
import './Utils/css/forms.css';
import './Utils/css/style-example.css';
import './Utils/css/react-bs-table.css';

import './Home/home.css';
import './Utils/css/media.css';

const Auth = React.lazy(() => import('./App/Auth'));
const UnAuth = React.lazy(() => import('./App/UnAuth'));

class App extends PureComponent {
  componentDidMount() {
    this.props.onLoad();

    const parsedDomain = windowLocationDomain();
    if (parsedDomain.subdomain && ![null, '', 'www', 'api', 'staging', 'stage'].includes(parsedDomain.subdomain)) {
      agent.Auth.checkDomain(parsedDomain.subdomain)
        .then((_res) => {})
        .catch((error) => {
          const { location } = window;
          location.hostname = location.hostname.replace(`${parsedDomain.subdomain}.`, 'www.');
         });
    }
  }

  render() {
    if (this.props.appLoaded) {
      if (appToken()) {
        return (<Auth />);
      }
      return (<UnAuth />);
    }
    return (
      <div />
    );
  }
}

const mapStateToProps = (state) => ({
  appLoaded: state.common.appLoaded,
  appName: state.common.appName,
  currentUser: state.common.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(currentUserLoad()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
