import { connect } from 'react-redux';
import React, { AppComponent, appDispatchToProps } from '../base-component';

class Notification extends AppComponent {
  constructor(props) {
    super(props);
    this.errorAlertElement = React.createRef();
    this.messageAlertElement = React.createRef();
  }

  componentDidMount() {
    const { errors, message } = this.props;

    if (errors) {
      this.errorAlertElement.current.style.display = 'block';
    }
    if (message) {
      this.messageAlertElement.current.style.display = 'block';
    }
  }

  hideAlert = (e) => {
    e.preventDefault();

    const { errors, message } = this.props;
    if (errors) {
      window.$(this.errorAlertElement.current).slideUp(300)
    }
    if (message) {
      window.$(this.messageAlertElement.current).slideUp(300)
    }
    this.props.onNotificationUnload();
  };

  render() {
    const { errors, message, dontHide, alertType } = this.props;

    if (errors) {
      return (
        <div className="alert invalid-top" role="alert" ref={this.errorAlertElement}>
          {!dontHide && <button className="close" onClick={this.hideAlert} aria-label="close">&times;</button>}
          <ul>
            {
              errors.map((error, index) => {
                if (typeof (error) === 'object') {
                  const keys = Object.keys(error);
                  return keys.map((key) => (
                    <li key={key}>{key === 'meta' ? error[key].message : error[key]}</li>
                  ));
                }
                return <li key={`error_${index}`}>{error}</li>;
              })
            }
          </ul>
        </div>
      );
    }
    if (message) {
      return (
        <div className={`alert alert-${alertType || 'success'}`} ref={this.messageAlertElement}>
          {!dontHide && <button className="close" onClick={this.hideAlert} aria-label="close">&times;</button>}
          <ul key="message">
            <li>{message}</li>
          </ul>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => appDispatchToProps(dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
