import {
  NOTIFICATION_UNLOADED,
  LOGIN,
  REGISTER,
  ASYNC_START,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_USER_BY_RESET_PASSWORD_TOKEN,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  const { errors } = action.payload || {};
  const error = (typeof (errors) !== 'undefined') && (errors.length > 0);
  switch (action.type) {
    case NOTIFICATION_UNLOADED:
      return {
        ...state,
        inProgress: false,
        message: null,
        errors: null,
      };
    case LOGIN:
    case REGISTER:
    case GET_USER_BY_RESET_PASSWORD_TOKEN:
    case RESET_PASSWORD:
    case FORGOT_PASSWORD:
      return {
        ...state,
        inProgress: false,
        errors: error ? action.payload.errors : null,
        message: action.payload.message || action.payload.meta?.message,
      };
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER) {
        return { ...state, inProgress: true };
      }
      break;
    default:
      return state;
  }

  return state;
};
