import {
  GET_USER_BY_RESET_PASSWORD_TOKEN,
  RESET_PASSWORD,
  LOGIN,
  REGISTER,
  APP_LOAD,
} from '../constants/actionTypes';
import agent from '../agent';
import { windowLocationDomain } from '../components/Utils';
import { appToken } from '../util/helpers/app';

export function userByResetPwdToken(payload) {
  return {
    type: GET_USER_BY_RESET_PASSWORD_TOKEN,
    payload,
  };
}

export function resetPwd(payload) {
  return {
    type: RESET_PASSWORD,
    payload,
  };
}

export function loginAction(payload) {
  return {
    type: LOGIN,
    payload,
  };
}

export function registerAction(payload) {
  return {
    type: REGISTER,
    payload,
  };
}

export function currentUserLoadAction(payload) {
  const token = appToken();
  return {
    type: APP_LOAD,
    payload,
    token,
  };
}

export function validResetPasswordToken(token) {
  const user = { subdomain: windowLocationDomain().subdomain, reset_password_token: token };
  return (dispatch) => agent.User.validResetPasswordToken(user)
    .then((res) => dispatch(userByResetPwdToken(res)))
    .catch((error) => dispatch(userByResetPwdToken(error.response.body)));
}

export function resetPassword(user) {
  return (dispatch) => agent.User.resetPassword(user)
    .then((res) => dispatch(resetPwd(res)))
    .catch((error) => dispatch(resetPwd(error.response.body)));
}

export function login(email, password) {
  return (dispatch) => agent.Auth.login(
    email, password, windowLocationDomain().subdomain,
  )
    .then((res) => dispatch(loginAction(res)))
    .catch((error) => dispatch(loginAction(error.response.body)));
}

export function register(name, phone, email, companyName, subdomain,
  password, confirmPassword, planDetails) {
  return (dispatch) => agent.Auth.register(
    name, phone, email, companyName, subdomain, password, confirmPassword, planDetails,
  )
    .then((res) => dispatch(registerAction(res)))
    .catch((error) => dispatch(registerAction(error.response.body)));
}

export function currentUserLoad() {
  return (dispatch) => {
    if (appToken()) {
      return agent.Auth.current()
        .then((res) => dispatch(currentUserLoadAction(res)))
        .catch((error) => dispatch(currentUserLoadAction(error.response.body)));
    }
    return dispatch(currentUserLoadAction(null)); // TODO : make else part promise
  };
}

